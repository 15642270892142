import React from 'react'
import PropTypes from 'prop-types'
import FeaturePageIntro from './FeaturePageIntro'
import { FeatureFooterNav } from './FeatureNav'

export default function FeatureDetailPage({ title, description, children }) {
	return (
		<div className="tt-screen-content">
			<FeaturePageIntro title={title} description={description}/>

			<div className="mt-5 mb-3">
				{children}
			</div>

			<FeatureFooterNav/>
		</div>
	);
}
FeatureDetailPage.propTypes = {
	title: PropTypes.any,
	description: PropTypes.any,
	children: PropTypes.any,
}
