import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames'
import { Location } from '@reach/router';
import { isPathMatch } from '../util/CommonUtil'
import RouterButton from './RouterButton'
import { makeStyles } from '@material-ui/core/styles'

const useRouterNavItemStyles = makeStyles(theme => ({
	navButton: {
		textTransform: "none",
	},
}), {name: "RouterNavItem"});

export default function RouterNavItem(props) {
	const {to, startIcon, endIcon, className, children} = props;
	const classes = useRouterNavItemStyles();
	let pathname = to;
	if (to.pathname) {
		pathname = to.pathname;
	}
	return (
		<Location>{locationProps => {
			let {location} = locationProps;
			const active = isPathMatch(location.pathname, pathname, true);
			return (
				<RouterButton
					to={to}
					variant={active ? "contained" : "text"}
					color="primary"
					fullWidth
					startIcon={startIcon}
					endIcon={endIcon}
					className={cn(classes.navButton, className)}
				>
					{children}
				</RouterButton>
			);
		}}</Location>
	);
}
RouterNavItem.propTypes = {
	to: PropTypes.string.isRequired,
	startIcon: PropTypes.node,
	endIcon: PropTypes.node,
	/** Extra CSS classes */
	className: PropTypes.string,
	children: PropTypes.any,
};
