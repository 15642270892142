import React from 'react'
import { useSiteMetadata } from '../../components/SiteData'
import AppLayout from "../../components/AppLayout";
import FeatureDisplay, { FeatureDisplayImage, FeatureText } from '../../components/FeatureDisplay'
import FeatureDetailPage from '../../components/FeatureDetailPage'
import PageInfo from '../../components/PageInfo'
import FeatureNav from '../../components/FeatureNav'
import { graphql } from 'gatsby'
import Brand from '../../components/shared/Brand'
import RouterLink from '../../components/shared/RouterLink'

export default function Expenses(props) {
	let {data} = props;
	const {productName} = useSiteMetadata();

	return (
		<AppLayout>
			<FeatureNav/>
			<FeatureDetailPage
				title="Expenses"
				description={(<>
					<p>
						Expenses often go hand in hand with <RouterLink to="/features/">time tracking</RouterLink>.
						And they become even more relevant with <RouterLink to="/features/invoicing/">invoicing</RouterLink>.
						You may enter your expenses in <Brand/> as you go about your day or later when you've accumulated a few.
						To see a view of your own expenses, use the convenient "My Expenses" screen.
					</p>
					<p>
						When you pair your expenses with automatic invoicing, it's easy to give your clients accurate,
						detailed, and timely invoices.
						You'll barely have to lift a finger, and this will give you the best chance of getting paid quickly.
					</p>
				</>)}
			>
				<PageInfo
					title={"Track Expenses with " + productName}
					description={"Track expenses with " + productName + ". Add an expense you just made, or enter expenses you incurred over a period of time. Give expense visibility to clients."}
				/>

				<section className="tt-limit-content-width">

					<FeatureDisplay
						header="Create and Edit Expenses"
						imgPlacement="right"
						img={<FeatureDisplayImage imgData={data.newExpenseImg.childImageSharp.gatsbyImageData} alt="Create New Expense"/>}
					>
						<FeatureText
							description={(<>
								<p>
									Save your expenses and monitor your team.
									If you're not working alone, you'll be able to easily access your team members'
									expenses. And when each billing period ends, you can include everyone's billable
									expenses on	a single invoice.
								</p>
								<p>The currency for each expense is determined by your team settings.</p>
								<p>
									When you add additional users on a team plan, you can grant them user permissions
									for view/edit access as needed.
								</p>
							</>)}
							small
						/>
					</FeatureDisplay>

					<FeatureDisplay
						header="Keep an Eye on Your Team's Expenses"
						odd
						imgPlacement="top"
						img={<FeatureDisplayImage imgData={data.expenseDashboardImg.childImageSharp.gatsbyImageData} alt="Expense Dashboard"/>}
					>
						<FeatureText
							description={<>
								<p>
									Avoid surprises by monitoring your team's expenses with <RouterLink to="/features/reporting/">reporting and dashboard visualization</RouterLink>.
									Apply filters and check previous periods.
								</p>
								<p>
									<Brand/> stores your expenses online.
									So you'll have immediate access to know what your team is spending money on.
								</p>
							</>}
							small
						/>
					</FeatureDisplay>

					<FeatureDisplay
						header="Adjust Your Profile Settings"
						imgPlacement="right"
						img={<FeatureDisplayImage imgData={data.profileSettingsImg.childImageSharp.gatsbyImageData} alt="User Settings"/>}
					>
						<FeatureText
							description={<>
								<p>
									Whether you are entering data or looking at results, you can customize your own
									preferred date and time formats.
								</p>
								<p>
									If you have team members, everyone can view time log and expense data in their own
									time zone and their own date and time formats.
								</p>
							</>}
							small
						/>
					</FeatureDisplay>

				</section>

			</FeatureDetailPage>
		</AppLayout>
	);
}
export const query = graphql`{
  newExpenseImg: file(relativePath: {eq: "images/features/expenses/new-expense.png"}) {
    childImageSharp { gatsbyImageData(width: 473, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  expenseDashboardImg: file(relativePath: {eq: "images/features/expenses/expense-dashboard.png"}) {
    childImageSharp { gatsbyImageData(width: 990, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  profileSettingsImg: file(relativePath: {eq: "images/features/expenses/profile-settings.png"}) {
    childImageSharp { gatsbyImageData(width: 474, placeholder: BLURRED, layout: CONSTRAINED) }
  }
}
`;
