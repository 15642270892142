import React from 'react'
import RouterNavItem from './shared/RouterNavItem'
import RouterLink from './shared/RouterLink'
import { useLocation } from '@reach/router'
import { isPathMatch } from './util/CommonUtil'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const featureLinks = [
	{to: "/features/", label: "Time Tracking", faIcon: ["far", "clock"], enableNavLink: true},
	{to: "/features/expenses/", label: "Expenses", faIcon: ["fas", "coins"], enableNavLink: true},
	{to: "/features/invoicing/", label: "Invoicing", faIcon: ["fas", "file-invoice"], enableNavLink: true},
	{to: "/features/reporting/", label: "Reporting", faIcon: ["fas", "table"], enableNavLink: true},
	{to: "/pricing/", label: "Pricing", faIcon: null, enableNavLink: false},
];

export default function FeatureNav(props) {
	return (
		<div className="container-fluid px-5 mt-4">
			<Grid container>
				{featureLinks.map(linkInfo => (
					linkInfo.enableNavLink && (
						<Grid key={linkInfo.to} item xs={12} md={3}>
							<RouterNavItem
								to={linkInfo.to}
								startIcon={<FontAwesomeIcon icon={linkInfo.faIcon} fixedWidth title={linkInfo.label}/>}
								className="text-nowrap"
							>
								{linkInfo.label}
							</RouterNavItem>
						</Grid>
					)
				))}
			</Grid>
		</div>
	);
};

export function FeatureFooterNav(props) {
	const location = useLocation();
	const {pathname} = location;

	let matchingIndex = -1;
	featureLinks.forEach((linkInfo, idx) => {
		if (isPathMatch(pathname, linkInfo.to, true)) {
			matchingIndex = idx;
		}
	});

	let previous = matchingIndex > 0 ? featureLinks[matchingIndex - 1] : null;
	let next = matchingIndex < featureLinks.length - 1 ? featureLinks[matchingIndex + 1] : null;

	return (
		(previous || next) && (
			<div className="container-fluid">
				<Grid container className="text-center">
					<Grid item xs={12} lg={6}>
						{previous && (
							<Typography variant="h5" component="div" className="pt-5 pb-5">
								<RouterLink
									to={previous.to || "/"}
									suppressDecoration
								>
									&larr; Previous{previous.label && ": " + previous.label}
								</RouterLink>
							</Typography>
						)}
					</Grid>
					<Grid item xs={12} lg={6}>
						{next && (
							<Typography variant="h5" component="div" className="pt-5 pb-5">
								<RouterLink
									to={next.to || "/"}
									suppressDecoration
								>
									Next{next.label && ": " + next.label} &rarr;
								</RouterLink>
							</Typography>
						)}
					</Grid>
				</Grid>
			</div>
		)
	);
}
