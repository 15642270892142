export function noTrailingSlash(str) {
	if (str.charAt(str.length - 1) === "/") {
		return str.substring(0, str.length - 1);
	}
	return str;
}

/**
 * Check if the current pathname matches the link pathname, ignoring a trailing slash character
 * @param currentPathname the pathname of the current location such as "/abc/def" or "/abc/def/"
 * @param linkPathname the pathname of the link such as "/abc/def" or "/abc/def/"
 * @param allowPathPrefix {boolean} true if the paths are considered a match when the current pathname ends with the link pathname instead of just when they are equal
 * @return {boolean} true if the current pathname matches that of the link
 */
export function isPathMatch(currentPathname, linkPathname, allowPathPrefix) {
	const p1 = noTrailingSlash(currentPathname);
	const p2 = noTrailingSlash(linkPathname);
	if (!allowPathPrefix) {
		return p1 === p2;
	}
	const idx = p1.indexOf(p2);
	return idx >= 0 && idx === p1.length - p2.length;
}
